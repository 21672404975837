import React from "react";

type AreaChartProps = {
    data: string;
}

export const AreaChart = ({ data }: AreaChartProps) => {

    return (
        <p>Hello</p>
    )
}
